import { createStore } from 'vuex'
import imgVid from './imgVid.js'
import shareArr from './shareArr.js'
const store = createStore({
    state: {
        admindata: {
            adminName: '管理员',
            action: -1,//一级导航索引
            paths: '',//页面当前路由地址
            IconShow: true,//是否关闭左侧
        },
    },//状态管理器中定义的数据源
    mutations: {
        getadmindata() {
            try {
                const admindata = JSON.parse(localStorage.getItem("admindata"));
                if (admindata == null) {
                    localStorage.setItem("admindata", JSON.stringify(store.state.admindata));
                } else {
                    store.state.admindata = JSON.parse(localStorage.getItem("admindata"))
                }
            } catch {
                localStorage.setItem("admindata", JSON.stringify(store.state.admindata));
            }
        },
        setadmindata() {
            localStorage.setItem("admindata", JSON.stringify(store.state.admindata));
        }
    },//同步操作
    actions: {},//异步操作
    getters: {}, //getters相当于计算属性
    modules: {
    imgVid,
    shareArr
    }
});

export default store



export default {
    namespaced: true,
    state: () => ({
        wordArr: [],//0新增
        MusicList:[],
    }),
    mutations: {
        setWordArr(state, params) {
            state.wordArr = [...params];
        },
        setMusicList(state, params) {
            state.MusicList = [...params];
        },

    },
    actions: {

        updateWordArr({ commit }, params) {
            commit('setWordArr', params);
        },
        updateMusicList({ commit }, params) {
            commit('setMusicList', params);
        },



    },
    getters: {
        wordArr: (state) => state.wordArr,
        MusicList: (state) => state.MusicList,
    },
};